.main_container {
  background-color: var(--gray);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.main_section {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 30px;
	border-radius: 12px;
	background-color: var(--white);
	margin: 2.5rem auto 0 auto;
  height: 556px;
  width: 700px;
}

.main_container .message {
  font-size: 18px;
  font-weight: 500;
  margin: 5px 0px;
  padding: 26px 0px 0px 0px;
  text-align: center;
  color: #3F3E38;
  white-space: pre-line;
}

.main_container h1 {
  font-size: 40px;
  font-family: var(--font-family-2);
  color: #3F3E38;
  height: 44px;
}

.main_container h1,
.main_container h2,
.main_container h3 {
  text-align: center;
  letter-spacing: 0px;
}

.main_container h2,
.main_container h3 {
  font-size: 24px;
}

.main_container h3 {
  color: #3F3E38;
}

.main_container .error_heading {
  color: var(--red);
  padding: 24px 0px 0px 0px;
  font-weight: 500;
}

.main_container .main_section .illustration_container {
  height: 250px;
  width: 250px;
}

.main_container .main_section .illustration_container img {
  height: 100%;
  width: 100%;
}


/* Tablet and above media queries */
@media screen and (max-width: 768px) {
  .main_container .main_section {
    height: 547px;
    width: 320px;
  }

  .main_container .content_container {
    width: 100%;
  }
  
  .main_container .main_section .illustration_container {
    height: 200px;
    width: 200px;
  }

  .main_container .main_section .illustration_container img {
    height: 100%;
    width: 100%;
  }
  
  .main_container h1 {
    font-size: 38px;
  }

  .main_container h2,
  .main_container h3 {
    font-size: 18px;
  }

  .main_container .error_heading {
    padding: 77px 0px 0px 0px;
  }

  .main_container .message {
    font-size: 14px;
  }
}
