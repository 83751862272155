.Main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Banner {
    height: 50px;
    padding: 0 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #CCEFF1;
    border-radius: 8px;
    margin: 20px 0;
}

.Tooltip {
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #CCEFF1;
}

.Text {
    font-size: 14px;
    font-weight: normal;
    color: #000000;
    padding: 0 10px;
}

.Content {
    height: fit-content;
    width: fit-content;
}

.Frame {
    height: 700px;
    width: 600px;
    border: 1px solid #707070;
}

.PCI_ThreeDS {
    height: 62px;
    width: 62px;
    border: none;
}

@media screen and (max-width: 1110px) {
    .Banner {
        height:80px;
        width: 90vw;
    }
    .Frame {
        height: 700px;
        width: 90vw;
    }
}

@media screen and (max-width: 760px) {
    .Banner {
        height:80px;
        width: 90vw;
    }
    .Frame {
        height: 700px;
        width: 90vw;
    }
}

@media screen and (max-width: 630px) {
    .Banner {
        height:90px;
        width: 95vw;
    }
    .Frame {
        height: 550px;
        width: 95vw;
    }
}

@media screen and (max-width: 338px) {
    .Banner {
        height:120px;
        width: 90vw;
        overflow: hidden;
    }
    .Frame {
        height: 700px;
        width: 100vw;
    }
}