.card {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}

p {
  text-align: center;
}
.card {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 80vw;
  height: 225px;
  font-size: 1.6rem;
  padding: 2.0rem;
  border-radius: 12px;
  background-color: var(--white);
  margin: 2.5rem 0 2.5rem 0;
  max-width: 340px;
}
.card_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content-text {
  padding: 20px;
}

.card p {
  font-weight: 800;
  margin: 8px;
  font-size: 14px;
}

a {
  text-decoration: none;
  color: var(--green);
  font-weight: bold;
}
a:hover {
  color: #3f3e38;
  text-decoration: underline;
}
@media screen and (min-width: 820px) {
  .card_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 30px;
  }
  .card {
    width: 30vw;
  }
}
