.main_container {
	background-color: var(--gray);
	display: flex;
	align-items: center;
	flex-direction: column;
}

.main_section {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 30px;
	border-radius: 12px;
	background-color: var(--white);
	margin: 2.5rem auto 0 auto;
	height: 556px;
	width: 700px;
}

.notFound_container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.notFound_container h2 {
	margin-top: 20px;
}

.notFound_container img {
	max-width: 100%;
	height: auto;
	text-align: center;
}


.notFound_container h1 {
	font-size: 40px;
	font-family: var(--font-family-2);
	color: #3F3E38;
	height: 44px;
}


.notFound_container h1,
.notFound_container h2,
.notFound_container h3 {
	text-align: center;
	letter-spacing: 0px;
}


.notFound_container .message {
	font-size: 18px;
	font-weight: 500;
	margin: 5px 0px;
	padding: 26px 0px 0px 0px;
	text-align: center;
	color: #3F3E38;
}


/* Tablet and above media queries */
@media screen and (max-width: 768px) {
	.main_container .main_section {
		height: 547px;
		width: 320px;
	}

	.notFound_container h1 {
		font-size: 38px;
	}

	.notFound_container .message {
		padding: 117px 0px 0px 0px;
		font-size: 14px;
	}
}
