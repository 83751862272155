.ikpaymentContainer__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ikpaymentContainer__footer .securityLogoPositioner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ikpaymentContainer__footer .securityLogoPositioner img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.ikpaymentContainer__footer .securityLogoPositioner img:not(last-child) {
  margin-right: 0.5rem;
}

@media screen and (max-width: 640px) {
  .ikpaymentContainer__footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 1.4rem;
  }
  .ikpaymentContainer__footer .divider {
    display: none;
  }
  .ikpaymentContainer__footer .poweredWith span {
    font-size: 14px;
  }
}
