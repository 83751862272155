.loaderAnimContainer {
  background-color: white;
  border-radius: 12px;
  width: 340px;
  height: 220px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0px 0px 8px 0px #dddddd;
  box-shadow: 0px 0px 8px 0px #dddddd;
}

.loaderAnimContainer .loaderImage {
  display: block;
}

.loaderAnimContainer .loaderImage.customIcon {
  max-width: 70px;
  height: auto;
}

.loaderAnimContainer label {
  color: #666666;
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 80%;
  text-align: center;
  font-size: 16px;
}

.loaderAnimContainer.expanded label {
	color: #666666;
	font-size: 24px;
	font-weight: bold;
}

.loaderAnimContainer.expanded {
  height: auto;
  width: 100%;
  max-width: 600px;
  min-height: 360px;
}

.loaderAnimContainer.expanded .loaderImage.customIcon {
  max-width: 200px;
  height: auto;
}
