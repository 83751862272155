.merchantLogoPositioner {
  position: absolute;
  top: 48px;
  left: 67px;
  z-index: 2;
}

.ikpaymentContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 220px;
  padding-top: 14rem;
  -webkit-transform: translate(-50%, -20%);
  transform: translate(-50%, -20%);
  width: 640px;
  height: 80%;
}

.ikpaymentContainer.loader {
  opacity: 0.2;
}

.ikpaymentContainer .paymentBgTop {
  position: absolute;
  top: 0px;
  width: 696px;
  height: auto;
}

.ikpaymentContainer .paymentBgBtm {
  position: absolute;
  top: 140px;
  width: 720px;
  height: 600px;
}

.ikpaymentContainer .ikpaymentContainer__content {
  width: 550px;
  position: relative;
  top: -100px;
}

.ikpaymentContainer .ikpaymentContainer__content .success_msg_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

.ikpaymentContainer
  .ikpaymentContainer__content
  .success_msg_container
  .success_ico
  img {
  width: 150px;
  height: auto;
}

.ikpaymentContainer .ikpaymentContainer__content .success_msg_container h1 {
  font-weight: 300;
  margin-top: 50px;
}

.ikpaymentContainer__header {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 1;
}

.ikpaymentContainer__header h1 {
  margin: 0;
  font-size: 28px;
  font-weight: 800;
  text-align: center;
  padding: 0px 0px 10px 0px;
}

.ikpaymentContainer__header h4 {
  color: #8B8B87;
  font-size: 20px;
  text-align: center;
  padding: 0px 0px 10px 0px;
  font-weight: 500;
}

.ikpaymentContainer__header .bankLogoPositioner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.ikpaymentContainer__header .bankLogoPositioner img:not(last-child) {
  margin-right: 0.5rem;
}

.ikpaymentContainer__paymentForm {
  position: relative;
  z-index: 1;
  max-width: 340px;
  margin: 0 auto;
}

.ikpaymentContainer__paymentForm .cardDetails {
  width: 100%;
}

.ikpaymentContainer__paymentForm .cardDetails label {
  font-size: 14px;
  font-weight: 600;
  margin-left: 0.8rem;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.ikpaymentContainer__paymentForm .cardDetails input {
  border-radius: 16px;
  background-color: #ffffff;
  border: 2px solid #1d1d1b;
  color: #4c505b;
  padding: 1.0rem;
  width: 100%;
  margin-top: 5px;
  height: 3.0rem;
  font-size: 1.0rem;
  padding-right: 3rem;
}

.ikpaymentContainer__paymentForm .cardDetails input:focus {
  outline-color: rgba(255, 206, 0, 0.2);
  border-color: #ffce00;
}

.ikpaymentContainer__paymentForm .cardDetails input + label::after {
  content: "*";
  font-weight: 600;
  margin-left: 5px;
  color: black;
}

.ikpaymentContainer__paymentForm .cardDetails input.invalid {
  color: #e54360;
  background-color: #ffffff;
  border: 2px solid #e54360;
}

.ikpaymentContainer__paymentForm .cardDetails input.invalid + label::after {
  color: #e54360;
}

.ikpaymentContainer__paymentForm .cardDetails input:required:invalid {
  color: #000000;
}

.ikpaymentContainer__paymentForm .cardDetails input option[value=""][disabled] {
  display: none;
}

.ikpaymentContainer__paymentForm .cardDetails input:disabled {
  background-color: #f9f9f9;
}

.ikpaymentContainer__paymentForm .cardDetails input.valid {
  border: 1px solid #8b8b87;
}

.ikpaymentContainer__paymentForm .cardDetails .nameOnCard > input {
  text-transform: uppercase;
}

.ikpaymentContainer__paymentForm .cardDetails .cardNumber {
  position: relative;
}

.ikpaymentContainer__paymentForm .cardDetails .field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  margin-bottom: 15px;
  position: relative;
}

.ikpaymentContainer__paymentForm .cardDetails .field:first-child {
  margin-top: 15px;
}

.ikpaymentContainer__paymentForm .cardDetails .fieldGroup.cardExtra {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ikpaymentContainer__paymentForm
  .cardDetails
  .fieldGroup.cardExtra
  .leftBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.ikpaymentContainer__paymentForm
  .cardDetails
  .fieldGroup.cardExtra
  .leftBlock
  .expiryBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  margin-right: 15px;
  position: relative;
}

.ikpaymentContainer__paymentForm
  .cardDetails
  .fieldGroup.cardExtra
  .leftBlock
  span {
  position: absolute;
  top: 27px;
  left: 100px;
  font-weight: bold;
  font-size: 28px;
  color: #8b8b87;
}

.ikpaymentContainer__paymentForm
  .cardDetails
  .fieldGroup.cardExtra
  .rightBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  position: relative;
}

.ikpaymentContainer__paymentForm
  .cardDetails
  .fieldGroup.cardExtra
  .rightBlock
  label
  span {
  width: 17px;
  height: 16px;
  background-color: #ffffff;
  text-align: center;
  border-radius: 10px;
  color: #3f3e38;
  border: 1px solid #3f3e38;
  float: right;
  cursor: pointer;
  margin-right: 2rem;
}

.ikpaymentContainer__paymentForm
  .cardDetails
  .fieldGroup.cardExtra
  .rightBlock
  label
  span:hover {
  border-color: #ffce00;
}

.ikpaymentContainer__paymentForm
  .cardDetails
  .fieldGroup.cardExtra
  .rightBlock
  label
  span:hover::after {
  content: "See the 3 digits on the back of your card";
  color: black;
  position: absolute;
  top: 0;
  right: -140px;
  background-color: #ffce00;
  padding: 8px;
  border-radius: 8px;
  font-size: 12px;
  width: 130px;
}

.ikpaymentContainer__paymentForm .cardDetails .fieldGroup.cardExtra input {
  width: 97px;
}

.ikpaymentContainer__paymentForm
  .cardDetails
  .fieldGroup.cardExtra
  input#cvv__code {
  width: 120px;
}

.ikpaymentContainer .fieldTick {
  position: absolute;
  width: 24px;
  height: 24px;
  bottom: 10px;
  right: 10px;
  display: none;
}

.ikpaymentContainer__paymentFormButtonsPositioner {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 340px;
  margin: 0 auto;
}

.ikpaymentContainer__paymentFormButtonsPositioner button.primary {
  background-color: #ffce00;
  border-radius: 16px;
  color: #1d1d1b;
  font-size: 20px;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  border: none;
  width: 100%;
  margin-top: 3.0rem;
  margin-bottom: 0rem;
}

.ikpaymentContainer__paymentFormButtonsPositioner button.primary:disabled {
  background-color: #e8e8e7;
  color: #bababa;
}

.ikpaymentContainer__paymentFormButtonsPositioner button.primary.teal {
  background-color: #09828b;
}

.ikpaymentContainer__paymentFormButtonsPositioner button.secondary {
  font-size: 1.6rem;
  font-weight: 600;
  color: #09828b;
  text-transform: capitalize;
  letter-spacing: 1px;
  cursor: pointer;
  background: none;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-top: 15px;
  margin-bottom: 30px;
}

.ikpaymentContainer__paymentFormButtonsPositioner button.secondary:hover {
  text-decoration: underline;
}

.ButtonBlock_buttonBlock {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
@media (max-width: 768px) {
	.ButtonBlock_buttonBlock {
		flex-direction: column;
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
  .merchantLogoPositioner {
    position: absolute;
    top: 15px;
    left: 20px;
  }
  .ikpaymentContainer {
    width: 375px;
    position: relative;
    margin: 0 auto;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 170px;
    padding-bottom: 0;
    overflow: hidden;
    -webkit-transform: none;
    transform: none;
    top: auto;
    left: auto;
    max-width: 100%;
  }
  .ikpaymentContainer .ikpaymentContainer__content {
    width: 280px;
  }
  .ikpaymentContainer .paymentBgTop {
    position: absolute;
    top: 0px;
    /* left: -5px; */
    width: 387px;
    height: auto;  
    max-width: 100%;
  }
  .ikpaymentContainer .paymentBgBtm {
    position: absolute;
    top: 112px;
    /* left: -12px; */
    width: 400px;
    height: 720px; 
    max-width: 103.5%;
  }
  .ikpaymentContainer__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .ikpaymentContainer__paymentForm .cardDetails .fieldGroup.cardExtra {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .ikpaymentContainer__paymentForm
    .cardDetails
    .fieldGroup.cardExtra
    .leftBlock
    .expiryBlock {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .ikpaymentContainer__paymentForm
    .cardDetails
    .fieldGroup.cardExtra
    .leftBlock
    input {
    width: 130px;
  }
  .ikpaymentContainer__paymentForm
    .cardDetails
    .fieldGroup.cardExtra
    .leftBlock
    span {
    left: 133px;
  }
  .ikpaymentContainer__paymentForm
    .cardDetails
    .fieldGroup.cardExtra
    .rightBlock {
    margin-left: 0;
    margin-top: 1.6rem;
  }
  .ikpaymentContainer__paymentForm
    .cardDetails
    .fieldGroup.cardExtra
    .rightBlock
    input#cvv__code {
    width: 100%;
  }
  .ikpaymentContainer__paymentForm
    .cardDetails
    .fieldGroup.cardExtra
    .rightBlock
    label {
    width: 100%;
  }
  .ikpaymentContainer__paymentForm
    .cardDetails
    .fieldGroup.cardExtra
    .rightBlock
    label
    span:hover::after {
    right: 0px;
    top: -26px;
  }
}

.loaderAnimContainer {
  background-color: white;
  border-radius: 12px;
  width: 340px;
  height: 220px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0px 0px 8px 0px #dddddd;
  box-shadow: 0px 0px 8px 0px #dddddd;
}

.loaderAnimContainer .loaderImage {
  display: block;
}

.loaderAnimContainer .loaderImage.customIcon {
  max-width: 70px;
  height: auto;
}

.loaderAnimContainer label {
  color: #666666;
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 80%;
  text-align: center;
  font-size: 16px;
}

.loaderAnimContainer.expanded {
  height: auto;
  width: 100%;
  max-width: 600px;
  min-height: 360px;
}

.loaderAnimContainer.expanded .loaderImage.customIcon {
  max-width: 200px;
  height: auto;
}

.loaderAnimContainer.expanded h1 {
  text-align: center;
  margin-bottom: 1.2rem;
  font-size: 30px;
}

.loaderAnimContainer.expanded label {
  color: #666666;
  font-size: 24px;
  font-weight: bold;
}

.loaderAnimContainer.expanded button.primary {
  width: 75%;
  margin-bottom: 3rem;
}

.loaderAnimContainer button.primary {
  background-color: #ffce00;
  border-radius: 12px;
  color: #1d1d1b;
  font-size: 1.8rem;
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  border: none;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 150px;
}

.loaderAnimContainer button.primary:disabled {
  background-color: #e8e8e7;
  color: #bababa;
}

.loaderAnimContainer button.secondary {
  font-size: 1.6rem;
  font-weight: 600;
  color: #09828b;
  text-transform: capitalize;
  letter-spacing: 1px;
  cursor: pointer;
  background: none;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-top: 15px;
  margin-bottom: 30px;
}

.loaderAnimContainer button.secondary:hover {
  text-decoration: underline;
}

button.primary_btn {
  background-color: #ffce00;
  border-radius: 12px;
  color: #1d1d1b;
  font-size: 1.8rem;
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  border: none;
  width: 100%;
}

button.primary_btn:disabled {
  background-color: #e8e8e7;
  color: #bababa;
}

button.primary_btn:hover {
  background-color: #1d1d1b;
  color: white;
}

.hide {
  display: none;
}
