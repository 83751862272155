.primary-button {
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 16px;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  color: #1D1D1B;
  background-color: var(--yellow);
}

.primary-button:hover {
  background-color: var(--black);
  color: var(--white);
  cursor: pointer;
  text-decoration: none;
}

.button-container p {
  padding: 12px 0px 0px 0px;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 50%;
  margin-top: 20px;
  letter-spacing: 0.32px;
}

/* Tablet and above media queries */
@media screen and (max-width: 768px) {
  .button-container {
    width: 90%;
  }
}

