@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;700&display=swap');

:root {
	--white: #ffff;
	--gray: #f7f7f7;
	--yellow: #ffcd00;
	--green: #09828b;
	--red: #e54360;
	--black: rgb(0, 0, 0);
	--font-family: "Roboto", sans-serif;
	--font-family-2: "Baloo 2", cursive;
}
html,
body {
	padding: 0;
	margin: 0;
	font-family: var(--font-family);
	/* font-size: 1.235rem; */
	background-color: var(--gray);
	color: #1D1D1B;
}

*,
*::before,
*::after {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

.support_header h2 {
	margin-top: 10px;
	font-size: 28px;
	text-align: center;
	font-weight: 500;
	color: #3F3E38;
}

/* Tablet and above media queries */
@media screen and (max-width: 768px) {
	.support_header h2 {
		font-size: 24px
	}
}

@media (max-width: 320px) {
	.layout {
		overflow-x: hidden !important;
	}
}
